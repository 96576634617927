import React, {useState} from 'react';


import Select from 'react-select';

import {FaHeading} from 'react-icons/fa';
import { Row, Col, Card, CardBody, CardHeader, Form, FormGroup, Input, Label, InputGroup, InputGroupText, Spinner, Button } from 'reactstrap';


const cropList = [
    { value: '1', label: 'Bitter Gourd' },
    { value: '2', label: 'Chilli' },
    { value: '3', label: 'Cucumber' },
    { value: '4', label: 'Brinjal' },
    { value: '5', label: 'Tomato' },
    { value: '6', label: 'Cowpea' },
    { value: '7', label: 'Basella' },
    { value: '8', label: 'Spinach' },
    { value: '9', label: 'Amaranthus' },
    { value: '10', label: 'Ridge Gourd' },
]

const AddFarmer = ({back}) => {
    const [hindiNameLoading, setHindiNameLoading] = useState(false);
    const [regNo, setRegNo] = useState('');
    const [name, setName] = useState('');
    const [nameHin, setNameHin] = useState('');
    const [spouseName, setSpouseName] = useState('');
    const [spouseNameHin, setSpouseNameHin] = useState('');
    const [age, setAge] = useState('');
    const [dob, setDob] = useState('');
    const [gender, setGender] = useState('');
    const [category, setCategory] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [altMobileNo, setAltMobileNo] = useState('');


    const [state, setState] = useState('');
    const [district, setDistrict] = useState('');
    const [block, setBlock] = useState('');
    const [panchayat, setPanchayat] = useState('');
    const [village, setVillage] = useState('');
    const [address, setAddress] = useState('');

    const add_handler = () => {

        let sendData = [{
            "org_id": sessionStorage.getItem('org_id'), 
            "user_id": "101",
            "f_reg_no": regNo,
            "f_name": name,
            "f_name_ne": nameHin,
            "age": age,
            "dob": dob,
            "mobile_no": mobileNo,
            "mobile_no_2": altMobileNo,
            "gender": gender,
            "category": category,
            "f_spouse_name": spouseName,
            "f_spouse_name_ne": spouseNameHin,
            "shareholder": "1",
            "state_id": state,
            "dist_id": district,
            "block_id": block,
            "pan_id": panchayat,
            "vill_id": village,
            "address": address,
            "crop_id": "1",
            "season_id": "1",
            "fy_id": "1",
            "facility_master_id": "11",
            "f_plot_no": "1",
            "holding_no": "2534",
            "holding_area": "25",
            "holding_type_id": "1",
        }]

    }

    const translate_text_handler = () => {

    }

    const getDistrictList = (e) => {

    }
    const getBlockList = (e) => {

    }

    const getPanchayatList = (e) => {

    }

    const getVillageList = (e) => {

    }

    return (
        <>
            <Card className='mb-3' color="primary" outline>
                <CardHeader> Add New Farmer </CardHeader>
                <CardBody>
                    <Form onSubmit={add_handler}>

                        <h5 className='text-primary borderDashed pb-1'>Basic Information</h5>

                        <FormGroup row>
                            <Label for="name" sm={2} > Registration Number (Optional) </Label>
                            <Col sm={4}>
                                <Input id="name" type='text' bsSize="sm"  value={regNo} onChange={(e) => setRegNo(e.target.value)} required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="name" sm={2} > Name </Label>
                            <Col sm={4}>
                                <Input id="name" type='text' bsSize="sm" onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={name} onChange={(e) => setName(e.target.value)} required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="hName" sm={2} > Name (Regional Language) </Label>
                            <Col sm={4}>
                                <InputGroup size="sm">
                                    <Input id="hName" type='text' value={nameHin} bsSize="sm" onChange={(e)=> setNameHin(e.target.value)} />
                                    <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? translate_text_handler : undefined}>
                                        {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                                    </InputGroupText>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="spouseName" sm={2} > Spouse Name </Label>
                            <Col sm={4}>
                                <Input id="spouseName" type='text' onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} bsSize="sm" value={spouseName} onChange={(e) => setSpouseName(e.target.value)} required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="hName" sm={2} > Name (Regional Language) </Label>
                            <Col sm={4}>
                                <InputGroup size="sm">
                                    <Input id="hName" type='text' value={spouseNameHin} bsSize="sm" onChange={(e)=> setSpouseNameHin(e.target.value)} />
                                    <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? translate_text_handler : undefined}>
                                        {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                                    </InputGroupText>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="age" sm={2} > Age </Label>
                            <Col sm={2}>
                                <Input id="age" type='text' bsSize="sm" maxLength={3} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={age} onChange={(e) => setAge(e.target.value)} required />
                            </Col>
                            <Col sm={2}>
                                <Input id="age" type='date' bsSize="sm" value={dob} onChange={(e) => setDob(e.target.value)} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="gender" sm={2} > Gender </Label>
                            <Col sm={4}>
                                <Input id="gender" bsSize="sm" type="select" value={gender} onChange={(e) => setGender(e.target.value)}>
                                    <option value="0"> --Select Gender--</option>
                                    <option value="1"> Male </option>
                                    <option value="2"> Female </option>
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="category" sm={2} > Category </Label>
                            <Col sm={4}>
                                <Input id="category" bsSize="sm" type="select" value={category} onChange={(e) => setCategory(e.target.value)}>
                                    <option value="0"> --Select Category--</option>
                                    <option value="1"> General </option>
                                    <option value="2"> OBC </option>
                                    <option value="3"> ST / SC </option>
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="mobileNo" sm={2} > Mobile Number</Label>
                            <Col sm={2}>
                                <Input id="mobileNo" type='text' bsSize="sm" maxLength={10} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} placeholder="Mobile Number" required />
                            </Col>
                            <Col sm={2}>
                                <Input id="mobileNo" type='text' bsSize="sm" maxLength={10} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={altMobileNo} onChange={(e) => setAltMobileNo(e.target.value)} placeholder="Alternate Mobile Number" />
                            </Col>
                        </FormGroup>

                        <h5 className='text-primary borderDashed mt-3 pb-1'>Geographical Information</h5>

                        <FormGroup row>
                            <Label for="state" sm={2} > State </Label>
                            <Col sm={4}>
                                <Input id="state" bsSize="sm" type="select" value={district} onChange={(e) => getDistrictList(e)}>
                                    <option value="0"> --Select State--</option>
                                    <option value="1"> Jharkhand </option>
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="district" sm={2} > District </Label>
                            <Col sm={4}>
                                <Input id="district" bsSize="sm" type="select" value={district} onChange={(e) => getBlockList(e)}>
                                    <option value="0"> --Select District--</option>
                                    <option value="1"> Bokaro </option>
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="block" sm={2} > Block </Label>
                            <Col sm={4}>
                                <Input id="block" bsSize="sm" type="select" value={block} onChange={(e) => getPanchayatList(e)}>
                                    <option value="0"> --Select Block--</option>
                                    <option value="1"> Chandankiyari </option>
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="village" sm={2} > Village </Label>
                            <Col sm={4}>
                                <Input id="village" bsSize="sm" type="select" value={village} onChange={(e) => setVillage(e.target.value)} >
                                    <option value="0"> --Select Village--</option>
                                    <option value="1"> Chas </option>
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="address" sm={2} > Address </Label>
                            <Col sm={4}>
                            <Input id="address" type='text' bsSize="sm" value={address} onChange={(e) => setAddress(e.target.value)} />
                            </Col>
                        </FormGroup>

                        <h5 className='text-primary borderDashed mt-3 pb-1'>Cropping Pattern</h5>

                        <FormGroup row>
                            <Label for="Rabi" sm={2} > Rabi season </Label>
                            <Col sm={6}>
                                <Select closeMenuOnSelect={false} isMulti
                                    options={cropList}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="Garma" sm={2} > Garma season </Label>
                            <Col sm={6}>
                                <Select closeMenuOnSelect={false} isMulti
                                    options={cropList}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="Kharif" sm={2} > Rabi season </Label>
                            <Col sm={6}>
                                <Select closeMenuOnSelect={false} isMulti
                                    options={cropList}
                                />
                            </Col>
                        </FormGroup>

                        

                        <h5 className='text-primary borderDashed mt-3 pb-1'>Source of irrigation</h5>
                        <Row>
                            <Col sm={2}>
                                <FormGroup check>
                                    <Input id="well" name="well" type="checkbox" />
                                    <Label className='p-2' check for="well"> Well </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={2}>
                                <FormGroup check>
                                    <Input id="pond" name="pond" type="checkbox" />
                                    <Label className='p-2' check for="pond"> Pond </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={2}>
                                <FormGroup check>
                                    <Input id="cannel" name="cannel" type="checkbox" />
                                    <Label className='p-2' check for="cannel"> Cannel </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={2}>
                                <FormGroup check>
                                    <Input id="rainfed" name="rainfed" type="checkbox" />
                                    <Label className='p-2' check for="rainfed"> Rainfed </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={1}>
                                <FormGroup check>
                                    <Input id="drip" name="drip" type="checkbox" />
                                    <Label className='p-2' check for="drip"> Drip </Label>
                                </FormGroup>
                            </Col>
                        </Row>

                        <h5 className='text-primary borderDashed mt-3 pb-1'>Land Type (In Acre)</h5>

                        <Row className='mb-3'>
                            <Col sm={2}>
                                <Label for="don"> Don </Label>
                                <Input id="don" type='text' bsSize="sm" />
                            </Col>
                            <Col sm={2}>
                                <Label for="baid"> Baid </Label>
                                <Input id="baid" type='text' bsSize="sm" />
                            </Col>
                            <Col sm={2}>
                                <Label for="tanr"> Tanr </Label>
                                <Input id="tanr" type='text' bsSize="sm" />
                            </Col>
                            <Col sm={2}>
                                <Label for="kanali"> Kanali </Label>
                                <Input id="kanali" type='text' bsSize="sm" />
                            </Col>
                            <Col sm={2}>
                                <Label for="homeStead"> Home Stead </Label>
                                <Input id="homeStead" type='text' bsSize="sm" />
                            </Col>
                        </Row>
                        <Row>
                            <hr />
                            <Col md={2} className="mb-2">
                                <Button className='text-white' color='primary' size="sm" block> Submit </Button>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
            <div className='text-end'>
                <Button className='text-white' color='primary' size="sm" onClick={back}> Back </Button>
            </div>
        </>
    )
}

export default AddFarmer;