import React, {useState, useEffect} from 'react';

import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';

import Chart from 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
// bar chart data

const optionstack = {
    scales: {
        xAxes: [{
            stacked: true
        }],
        yAxes: [{
            stacked: true
        }]
    }
};

// dognut chart data
/*const optionsPie= {
    legend: { display: false, position: "right" },
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      arc: {
        borderWidth: 0
      }
    },
  };*/

  const optionsPie = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      position: 'right',
      
    }
}
//
const KitchenGardenDashboard = () => {
    const [loading, setLoading] = useState(true);
    const [graphData, setGraphData] = useState([]);
    const [doughnutData, setDoughnutData] = useState([]);

    useEffect(()=>{
        get_graph();
    },[]);

    const get_graph = () => {
        let dataPie = {
            maintainAspectRatio: true,
            responsive: true,
            labels: ['ABC', 'EFG', 'HGS', 'KLBD',],
            datasets: [
              {
                data: [560, 120, 420, 240],
                backgroundColor: ['#4d8d6e','#fcb929','#83abd6','#f76363'],
              }
            ]
        };

        setDoughnutData(dataPie);
        
        //

        let temp = [
            {
                label: "Open",
                data: [12,20,15,50,62,32,85],
                backgroundColor: '#4d8d6e',
            },
            {
                label: "Close",
                data: [32,30,25,10,12,52,45],
                backgroundColor: '#fcb929',
            }
        ];
        let dName = ['a', 'b', 'c', 'd', 'e', 'f', 'g'];

        setGraphData({ "labels": dName, "datasets": temp });
        setLoading(false);
    }

    return (
        <>
            <Row className='ps-2 pe-2 mb-3'>
                <Col xs={4}>
                    <Card color="primary" outline>
                        <CardHeader>Cultivation</CardHeader>
                        <CardBody>
                            {!loading &&
                                <Doughnut
                                    data={doughnutData}
                                    options={optionsPie}
                                    height="150px"
                                />
                            }
                        </CardBody>
                    </Card>
                </Col>
                <Col xs={4}>
                    <Card color="primary" outline>
                        <CardHeader>Distribution</CardHeader>
                        <CardBody>
                            {!loading &&
                                <Doughnut
                                    data={doughnutData}
                                    options={optionsPie}

                                />
                            }
                        </CardBody>
                    </Card>
                </Col>
                <Col xs={4}>
                    <Card color="primary" outline>
                        <CardHeader>Training</CardHeader>
                        <CardBody>
                            {!loading &&
                                <Doughnut
                                    data={doughnutData}
                                    options={optionsPie}

                                />
                            }
                        </CardBody>
                    </Card>
                </Col>

            </Row>
            <Row className='ps-2 pe-2 mb-3'>
                <Col md={6}>
                    <Card color="primary" outline style={{ minHeight: "250px" }}>
                        <CardHeader>Geographical Data</CardHeader>
                        <CardBody>
                            <Row className='p-1'>
                                <Col xs={4}><div className='f-bold'>District</div></Col>
                                <Col><div className='f-bold'>Distribution</div></Col>
                                <Col><div className='f-bold'>Cultivation</div></Col>
                                <Col><div className='f-bold'>Farmer</div></Col>
                            </Row>
                            <Row className='p-1'>
                                <Col xs={4}><div className='f-bold'>Purbi Singhbhum</div></Col>
                                <Col>80 </Col>
                                <Col>60 </Col>
                                <Col>125</Col>
                            </Row>
                            <Row className='p-1'>
                                <Col xs={4}><div className='f-bold'>Paschim Singhbhum</div></Col>
                                <Col>88 </Col>
                                <Col>68 </Col>
                                <Col>178</Col>
                            </Row>
                            <Row className='p-1'>
                                <Col xs={4}><div className='f-bold'>Simdega</div></Col>
                                <Col>53 </Col>
                                <Col>65 </Col>
                                <Col>165</Col>
                            </Row>
                            <Row className='p-1'>
                                <Col xs={4}><div className='f-bold'>Deoghar</div></Col>
                                <Col>62 </Col>
                                <Col>66 </Col>
                                <Col>158</Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>

                <Col md={6}>
                    <Card color="primary" outline style={{ minHeight: "250px" }}>
                        <CardHeader>Graphical Data</CardHeader>
                        <CardBody>
                            {!loading && <Bar options={optionstack} data={graphData} height="100%" />}
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </>
    )
}

export default KitchenGardenDashboard;