import React from 'react';

function Footer() {

    return (
        <div className='text-center'>
            <small>© All Rights Reserved By boxfarming</small>
        </div>
    )
}

export default Footer;