import React, { useState, useEffect } from 'react';
import { notification } from '../../hocs/notification';
import axios from 'axios';
import { mainurl } from '../../../Mainurl';

import Select from 'react-select';
import { Row, Col, Card, CardBody, CardHeader, Form, FormGroup, Input, Label, Spinner, Button } from 'reactstrap';

const RegisterForm = ({ back, reset }) => {
    
    const [orgName, setOrgName] = useState('');
    const [type, setType] = useState('');

    const [contactPerson, setContactPerson] = useState('');
    const [state, setState] = useState('');
    const [address, setAddress] = useState('');
    const [altMobileNo, setAltMobileNo] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [email, setEmail] = useState('');
    const [noFarmer, setNoFarmer] = useState('');
    const [authorizedCapital, setAuthorizedCapital] = useState('');
    const [paidupCapital, setPaidupCapital] = useState('');

    const cropList = [
        { value: '1', label: 'Bitter Gourd' },
        { value: '2', label: 'Chilli' },
        { value: '3', label: 'Cucumber' },
        { value: '4', label: 'Brinjal' },
        { value: '5', label: 'Tomato' },
        { value: '6', label: 'Cowpea' },
        { value: '7', label: 'Basella' },
        { value: '8', label: 'Spinach' },
        { value: '9', label: 'Amaranthus' },
        { value: '10', label: 'Ridge Gourd' },
    ]

    const add_handler = (e) => {
        e.preventDefault();
        /*let postData = [ {
            "org_id": '101' ,
            "user_id": '10001',
            "user_name": name,
            "user_name_ne": nameHin,
            "user_email": email,
            "user_phone": mobileNo,
            "role_code": role,
            "password": password,
        }];

        if(passwordValid && role > 0){
            axios({
                method: 'post',
                url: mainurl + 'Master/box_user_add',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
            })
            .then(function (response) {
                let user_id = response.data[0].retcode;
                let notify = notification({ message: "User added Successfully. User Id is = "+user_id+".", type: 'info', time:false, position:'center' });
                notify();
                reset();
            })
            .catch(error => {
                let notify = notification({ message: "Submit failed! Try Again", type: 'error',  time:true });
                notify();
            });
        }else{
            let notify = notification({ message: "Password not match! or User Role not selected.", type: 'warn', time:true });
            notify();
        }*/

    }


    return (
        <div className=' p-5'>
            <Card className='mb-3' color="primary" outline>
                <CardHeader> Register New Organization </CardHeader>
                <CardBody>
                    <Form onSubmit={add_handler}>
                        <Row>
                            <FormGroup row>
                                <Label for="name" sm={2} > Organisation Name* </Label>
                                <Col sm={4}>
                                    <Input id="name" type='text' bsSize="sm" onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={orgName} onChange={(e) => setOrgName(e.target.value)} placeholder="Organisation Name" required />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label for="type" sm={2} > Type Of Organization* </Label>
                            <Col sm={4}>
                                <Input id="type" bsSize="sm" type="select" value={type} onChange={(e) => setType(e.target.value)}>
                                    <option value="0"> --Select Type--</option>
                                    <option value="1"> FPO </option>
                                    <option value="2"> Individual Farmer </option>
                                </Input>
                            </Col>
                        </FormGroup>
                            <h5 className='text-primary borderDashed mt-3 pb-1'>Contact Details</h5>
                            <FormGroup row>
                                <Label for="contactPerson" sm={2} > Contact Person Name* </Label>
                                <Col sm={4}>
                                    <Input id="contactPerson" type='text' bsSize="sm" onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={contactPerson} onChange={(e) => setContactPerson(e.target.value)} placeholder="Contact Person Name" required />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label for="mobileNo" sm={2} > Mobile Number*</Label>
                                <Col sm={2}>
                                    <Input id="mobileNo" type='text' bsSize="sm" maxLength={10} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} placeholder="Mobile Number" required />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label for="altMobileNo" sm={2} > Alternate Mobile Number</Label>
                                <Col sm={2}>
                                    <Input id="altMobileNo" type='text' bsSize="sm" maxLength={10} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={altMobileNo} onChange={(e) => setAltMobileNo(e.target.value)} placeholder="Alternate Mobile Number" />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label for="email" sm={2} > Organization Email* </Label>
                                <Col sm={4}>
                                    <Input id="email" type='text' bsSize="sm" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required/>
                                </Col>
                            </FormGroup>

                            <h5 className='text-primary borderDashed mt-3 pb-1'>Address Details</h5>

                            <FormGroup row>
                                <Label for="state" sm={2} > State* </Label>
                                <Col sm={4}>
                                    <Input id="state" type='text' bsSize="sm" value={state} onChange={(e) => setState(e.target.value)} placeholder="State" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="address" sm={2} > Address* </Label>
                                <Col sm={4}>
                                    <Input id="address" type='textarea' bsSize="sm" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Address" />
                                </Col>
                            </FormGroup>
                            <h5 className='text-primary borderDashed mt-3 pb-1'>Other Details</h5>
                            <FormGroup row>
                                <Label for="noFarmer" sm={2} > Number of Shareholder* </Label>
                                <Col sm={4}>
                                    <Input id="noFarmer" type='text' bsSize="sm" value={noFarmer} onChange={(e) => setNoFarmer(e.target.value)} placeholder="Total Shareholder" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="authorizedCapital" sm={2} > Authorized Capital </Label>
                                <Col sm={4}>
                                    <Input id="authorizedCapital" type='text' bsSize="sm" value={authorizedCapital} onChange={(e) => setAuthorizedCapital(e.target.value)} placeholder="Authorized Capital" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="paidupCapital" sm={2} > Paidup Capital </Label>
                                <Col sm={4}>
                                    <Input id="paidupCapital" type='text' bsSize="sm" value={paidupCapital} onChange={(e) => setPaidupCapital(e.target.value)} placeholder="Paidup Capital" />
                                </Col>
                            </FormGroup>
                            
                            <h5 className='text-primary borderDashed mt-3 pb-1'>Cropping Pattern</h5>

                        <FormGroup row>
                            <Label for="Rabi" sm={2} > Rabi season* </Label>
                            <Col sm={6}>
                                <Select closeMenuOnSelect={false} isMulti
                                    options={cropList}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="Garma" sm={2} > Garma season* </Label>
                            <Col sm={6}>
                                <Select closeMenuOnSelect={false} isMulti
                                    options={cropList}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="Kharif" sm={2} > Kharif season* </Label>
                            <Col sm={6}>
                                <Select closeMenuOnSelect={false} isMulti
                                    options={cropList}
                                />
                            </Col>
                        </FormGroup>

                            <hr />
                            <Col md={2} className="mb-2">
                                <Button className='text-white' color='primary' size="sm" block> Register </Button>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </div>
    )
}

export default RegisterForm;