
import { Routes, Route } from "react-router-dom";
import './style/main.scss';

import Home from './pages/Home';
import Login from "./pages/Login";
import RegisterOrg from "./pages/RegisterOrg";
import WIP from './pages/WIP';

//Fram Activity
import Traceability from "./pages/farmActivity/Traceability";
import Meeting from "./pages/farmActivity/Meeting";
import Training from "./pages/farmActivity/Training";
import Distribution from "./pages/farmActivity/Distribution";

//Advisory
import InputCategory from './pages/advisory/InputCategory';

//Master
import Farmer from "./pages/master/Farmer";
import Pg_Vo from "./pages/master/Pg_Vo";
import Employee from "./pages/master/Employee";
import UserManagement from "./pages/master/UserManagement";
import CropMaster from "./pages/master/CropMaster";
import CropVariety from "./pages/master/CropVariety";
//Report

//Setting
import Uom from "./pages/setting/Uom";
import Language from "./pages/setting/Language";
import Organisation from "./pages/setting/Oraganization";


function App() {
  return (
    <div className="App" style={{marginTop: "70px"}}>
      <Routes>
        <Route path="/" element={ <Login /> } />
        <Route path="/register" element={ <RegisterOrg /> } />
        <Route path="/home" element={ <Home /> } />
        
        <Route path="/traceability" element={ <Traceability /> } />
        <Route path="/meeting" element={ <Meeting /> } />
        <Route path="/training" element={ <Training /> } />
        <Route path="/distribution" element={ <Distribution /> } />
        
        <Route path="/input" element={ <InputCategory /> } />

        <Route path="/farmer" element={ <Farmer /> } />
        <Route path="/pg" element={ <Pg_Vo /> } />
        <Route path="/employee" element={ <Employee /> } />
        <Route path="/user-management" element={ <UserManagement /> } />
        <Route path="/crop-master" element={ <CropMaster /> } />
        <Route path="/crop-variety" element={ <CropVariety /> } />
        
        <Route path="/uom" element={ <Uom /> } />
        <Route path="/languages" element={ <Language /> } />
        <Route path="/Organisation" element={ <Organisation /> } />

        <Route path="*" element={ <WIP /> } />
      </Routes>
    </div>
  );
}

export default App;